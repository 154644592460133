export const HomepageProjectDescriptionOne = () => {
  return (
    <div className="project-description-container">
      <h2>Text Q&amp;A</h2>
      <p>
        An interactive question-and-answer app that allows public listers get
        instant answers to questions posed during live talks
      </p>
    </div>
  );
};
