export const HomepageProjectDescriptionThree = () => {
  return (
    <div className="project-description-container">
      <h2>Fully Captured</h2>
      <p>
        A cloud-based mobile messaging app targeted at mobile phone
        photographers
      </p>
    </div>
  );
};
