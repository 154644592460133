export function HomepageImageBlock3() {
  return (
    <a href="">
      <img
        className="project-image"
        // id="project-homepagee"
        src="/images/fully-captured.homep.webp"
        alt="Preview of my latest project"
      />
    </a>
  );
}
