export const TextQaImg1 = () => {
  return (
    <div>
      <div className="image-filler"></div>
      <img
        className="project-image"
        src="images/NEW-Opening.webp"
        alt="image of project"
      />
    </div>
  );
};
