export const HomepageProjectDescriptionFour = () => {
  return (
    <div className="project-description-container">
      <h2>ARC Global Churches, ARC Thurrock</h2>
      <p>
        Aesthetic website of a charitable organization. Contains various
        infomration about the organization, and social media content.
      </p>
    </div>
  );
};
